import React from "react"
import Flex from "@components/elements/flex"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import GradientText from "@components/common/gradient-text"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import theme from "@styles/theme"
import { useTheme } from "styled-components"

export const FeaturedResource = ({
  name,
  summary,
  slug,
  image,
  dashboard,
  bottom,
}) => {
  const { color } = useTheme()

  return (
    <Flex
      background={color.blueLight}
      justifyContent="center"
      borderRadius="1rem"
      padding="3rem"
    >
      {!bottom && (
        <Div>
          <Image
            radius={0.5}
            shadow
            file={image}
            showOverflow
            borderStyle={{
              boxShadow: theme.shadow.dramatic,
            }}
            style={{
              width: "100%",
              marginBottom: "3rem",
            }}
          />
        </Div>
      )}
      <Div center zIndex={1}>
        <Paragraph fontSize="1rem" fontWeight="700" center margin="0 0 0.5rem" color={color.blue600}>
            {dashboard ? `Dashboard` : `KPI Example`}
        </Paragraph>
        <Heading as="h2" fontSize="1.6rem" center margin="0 0 1.5rem">
          <Anchor
            arrow
            color={theme.color.blue500}
            hover={theme.color.blue300}
            link={
              dashboard ? `dashboard-examples/${slug}` : `kpi-examples/${slug}`
            }
          >
            {name}
          </Anchor>
        </Heading>
        <Paragraph lineHeight="1.6" center fontSize="1.1rem">
          {summary}
        </Paragraph>
      </Div>
      {bottom && (
        <Div>
          <Image
            radius={1}
            shadow
            noDimensions
            file={image}
            showOverflow
            borderStyle={{
              boxShadow: theme.shadow.dramatic,
            }}
            style={{
              marginTop: "2rem",
            }}
          />
        </Div>
      )}
    </Flex>
  )
}

FeaturedResource.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  dashboard: PropTypes.bool,
  bottom: PropTypes.bool,
}

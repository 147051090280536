import React from "react"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import Image from "@components/image"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import theme from "@styles/theme"
import Flex from "@components/elements/flex"
import { Link } from "gatsby"
import styled from "styled-components"
import { useTheme } from "styled-components"
const StyledLink = styled(Link)`
  h4 {
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    h4 {
      color: ${({ theme }) => theme.color.blue500};
    }
  }
`

export const ResourcesFeaturedCategory = ({
  name,
  subtitle,
  slug,
  dashboard,
  examples,
}) => {
  const { color } = useTheme()

  return (
    <Grid gap="2rem" columns="1fr 2fr" columnsMd="1fr" columnsSm="1fr">
      <Div
        background="#f3f5fc"
        borderRadius="1rem"
        padding="2rem"
        height="300px"
      >
        <Heading fontWeight="700" fontSize="1.4rem" as="h3" margin="0 0 1rem">
          <Anchor
            arrow
            link={
              dashboard
                ? `/resources/dashboard-examples/${slug}`
                : `/resources/kpi-examples/${slug}`
            }
          >
            {name} {dashboard ? "Dashboards" : "KPIs"}
          </Anchor>
        </Heading>
        <Paragraph lineHeight="1.6" fontSize="1rem" margin="0 0 1rem">
          {subtitle}
        </Paragraph>
      </Div>
      <Grid gap="2rem" columns="repeat(2, 1fr)" columnsMd="1fr" columnsSm="1fr">
        {examples.map(
          (example, i) =>
            i < 4 && (
              <StyledLink
                key={
                  dashboard
                    ? `dashboard-category-example-${i}`
                    : `kpi-category-example-${i}`
                }
                to={
                  dashboard
                    ? `dashboard-examples/${slug}/${example.slug}`
                    : `kpi-examples/${slug}/${example.slug}`
                }
              >
                <Flex
                  background="#f3f5fc"
                  borderRadius="1rem"
                  padding="2rem"
                  height="300px"
                  margin="0 0 2rem"
                  justifyContent="center"
                >
                  <Image
                    radius={0.5}
                    file={example.image}
                    objectFit="scale-down"
                    showOverflow
                    borderStyle={{
                      boxShadow: theme.shadow.dramatic,
                    }}
                  />
                </Flex>
                <Paragraph
                  fontSize="1rem"
                  color={color.blue600}
                  fontWeight="700"
                  margin="0 0 0.5rem"
                >
                  {dashboard ? `Dashboard Example` : `KPI Example`}
                </Paragraph>
                <Heading as="h4" color={color.blue400} margin="0 0 1rem">
                  {example.name ?? example.h1 ?? example.title}
                </Heading>
                <Paragraph lineHeight="1.6" fontSize="1rem" margin="0 0 1rem">
                  {example.subtitle}
                </Paragraph>
              </StyledLink>
            )
        )}
      </Grid>
    </Grid>
  )
}

ResourcesFeaturedCategory.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  dashboard: PropTypes.bool,
  examples: PropTypes.array.isRequired,
}
